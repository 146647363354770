import { render, staticRenderFns } from "./FacturePdf.vue?vue&type=template&id=7447e60d&scoped=true&"
import script from "./FacturePdf.vue?vue&type=script&lang=js&"
export * from "./FacturePdf.vue?vue&type=script&lang=js&"
import style0 from "./FacturePdf.vue?vue&type=style&index=0&id=7447e60d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7447e60d",
  null
  
)

export default component.exports