<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>
<style>
/* @import './assets/css/transparent-login-form.css'; */
.v-application {
  background-color: transparent !important;
}
/** {
  font-family: "IBM Plex Sans", sans-serif;
}*/
</style>
