<template>
  <v-container
    v-bind:style="[
      mounted && $refs.payment.type_account == 'sandbox'
        ? { border: '6px solid #ff8c00' }
        : '',
    ]"
    :fill-height="!$vuetify.breakpoint.xsOnly"
  >
    <Payment ref="payment" />
  </v-container>
</template>

<script>
import Payment from "../components/PaymentModal";

export default {
  name: "Home",
  components: {
    Payment,
  },
  data() {
    return {
      mounted: false,
    };
  },

  mounted() {
    this.mounted = true;
  },
};
</script>
