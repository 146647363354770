import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import FacturePdf from "../views/FacturePdf.vue";
import PaymentPage from "../views/PaymentPage.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/page/:token",
    name: "paymentpage",
    component: PaymentPage,
  },
  { path: "/not-found", name: "notfound", component: PageNotFound },
  {
    path: "/facture/:token",
    name: "Facture",
    component: FacturePdf,
    props: (route) => ({ token: route.params.token }),
  },
  {
    path: "/:token/:vue?",
    name: "notimes",
    component: Home,
    props: (route) => ({ code: route.params.code, token: route.params.token }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let params = to.params ?? null;
  if (to.path == "/not-found") {
    next();
  } else if (params.token == null || params.token == "") {
    router.push({ path: "/not-found" });
  } else {
    next();
  }
});

export default router;
