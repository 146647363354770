import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FlagIcon from "vue-flag-icon";
import axios from "axios";
import Echo from "laravel-echo";
import i18n from "./i18n";
window.io = require("socket.io-client");

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;
Vue.use(FlagIcon);

if (typeof io !== "undefined") {
  window.Echo = new Echo({
    broadcaster: "socket.io",
    transports: ["polling", "websocket"],
    host: "https://sandbox.apimpay.webcoom.net",
  });
}

// window.Echo = new Echo({
//   broadcaster: "socket.io",
//   transports: ["polling", "websocket"],
//   host: window.location.hostname + ":6001",
// });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
