import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    langLocal: "fr",
    listPrefix: null,
    selectList: null,
  },
  getters: {
    langLocal(state) {
      return state.langLocal;
    },
    listPrefix(state) {
      return state.listPrefix;
    },
    selectList(state) {
      return state.selectList;
    },
  },
  mutations: {
    SET_LANGLOCAL(state, value) {
      state.langLocal = value;
      i18n.locale = state.langLocal;
    },
    SET_LISTPREFIX(state, value) {
      state.listPrefix = value;
    },
    SET_SELECTLIST(state, value) {
      state.selectList = value;
    },
  },
  actions: {
    async setlanguage({ commit }, payload) {
      commit("SET_LANGLOCAL", payload);
    },

    async confirmpayment({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/cardPayment", payload);
        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async getPaymentInfo({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        const formdata = new FormData();
        formdata.append("token", payload);
        let response = await axios.post("api/v1/getinfoToken", formdata);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },

    async getPaymentPageInfo({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        const formdata = new FormData();
        formdata.append("token", payload);
        let response = await axios.post("api/v1/infoPage", formdata);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getTokenPayment({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/getTokenPayment", payload);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async mobilePayment({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/mobilePayment", payload);
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async pagePayment({ commit }, payload) {
      await axios.get("sanctum/crf-cookie");
      try {
        let response = await axios.post("api/v1/getinfoTokenPage");
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async initializePayment({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/cardPaymentIntement", payload);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getPrefix({ commit }) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/prefix");
        var list = response.data;
        var result = [];
        if (list) {
          list.forEach((item, index) => {
            result[index] = {
              code: item.country,
              flag: item.code,
              prefix: item.prefix,
              fee: item.taux_reseau,
            };
          });

          commit("SET_LISTPREFIX", response.data);
          commit("SET_SELECTLIST", result);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCustomerInfo({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        const formdata = new FormData();
        formdata.append("token", payload);
        let response = await axios.post("api/v1/getCustomerInfo", formdata);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getCallBackMoov({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post(
          "api/v1/mpaycallbackmoovmoney",
          payload
        );
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },

    async getFees({ commit }, payload) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/v1/fees", payload);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  plugins: [createPersistedState()],
  modules: {},
});
