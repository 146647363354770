<template>
  <v-container>
    <div v-show="!error_message" id="factureHtml" ref="factureHtml">
      <div
        style="
          background: linear-gradient(90deg, #102e49, #b04647);
          color: white;
          padding: 5px;
        "
      >
        <v-row>
          <v-col md="9">
            <img style="width: 100px; margin-left: 25px" :src="top_logo" />
            <h3 style="padding: 20px; font-size: 40px">Facture</h3>
          </v-col>
        </v-row>
      </div>
      <v-container style="padding-left: 30px; padding-right: 30px">
        <v-row>
          <v-col md="9">
            <h6 style="font-size: 12px; font-weight: 300">Nom Prénom :</h6>
            <h5 style="font-size: 14px; font-weight: 600">
              {{ lastname }} {{ firstname }}
            </h5>
            <h6 style="font-size: 12px; font-weight: 300">Email :</h6>
            <h5 style="font-size: 14px; font-weight: 600">
              {{ email }}
            </h5>
            <h6
              v-if="paiement_type == 'Mobile'"
              style="font-size: 12px; font-weight: 300"
            >
              N° de Téléphone :
            </h6>
            <h5
              v-if="paiement_type == 'Mobile'"
              style="font-size: 14px; font-weight: 600"
            >
              {{ phonenumber }}
            </h5>
            <h6 v-if="paiement_type" style="font-size: 12px; font-weight: 300">
              Mode de Paiement :
            </h6>
            <h5 v-if="paiement_type" style="font-size: 14px; font-weight: 600">
              {{ paiement_type }}
            </h5>
          </v-col>
          <v-col md="3">
            <h6 style="font-size: 12px; font-weight: 300; text-align: right">
              Facture #
            </h6>
            <h5 style="font-size: 14px; font-weight: 600; text-align: right">
              {{ facture_code }}
            </h5>
            <h6 style="font-size: 12px; font-weight: 300; text-align: right">
              Date :
            </h6>
            <h5 style="font-size: 14px; font-weight: 600; text-align: right">
              {{ transaction_date }}
            </h5>
          </v-col>
        </v-row>
        <v-container>
          <v-divider
            style="
              border-style: solid;
              border-width: 1px;
              border-color: #102e49;
            "
          ></v-divider>
          <v-row style="background-color: #102e49; padding: 5px">
            <v-col md="9">
              <h6 style="font-size: 14px; font-weight: 400; color: #fff">
                Produit
              </h6>
            </v-col>
            <v-col md="3">
              <h6
                style="
                  font-size: 14px;
                  font-weight: 400;
                  text-align: right;
                  color: #fff;
                "
              >
                Montant
              </h6>
            </v-col>
          </v-row>
          <v-row style="padding-left: 4px; padding-right: 4px">
            <v-col md="9">
              <h6 style="font-size: 12px; font-weight: 500">Paiement en POS</h6>
            </v-col>
            <v-col md="3">
              <h6 style="font-size: 12px; font-weight: 500; text-align: right">
                {{ amount_custumer }}
              </h6>
            </v-col>
          </v-row>
          <v-row style="padding-left: 4px; padding-right: 4px">
            <v-col md="9">
              <h6 style="font-size: 12px; font-weight: 500">
                Frais de transaction
              </h6>
            </v-col>
            <v-col md="3">
              <h6 style="font-size: 12px; font-weight: 500; text-align: right">
                {{ amout_wbc }}
              </h6>
            </v-col>
          </v-row>
          <v-row style="padding: 4px">
            <v-col md="9">
              <h6 style="font-size: 12px; font-weight: 300">Total</h6>
            </v-col>
            <v-col md="3">
              <h6 style="font-size: 12px; font-weight: 500; text-align: right">
                {{ total_amount }}
              </h6>
            </v-col>
          </v-row>
          <v-divider
            style="
              border-style: solid;
              border-width: 3px;
              border-color: #102e49;
            "
          ></v-divider>
        </v-container>
        <v-spacer style="margin-top: 6px; margin-bottom: 6px"></v-spacer>
        <v-container>
          <v-row
            v-if="meta_data != null && Object.keys(meta_data).length > 0"
            class="no-space"
          >
            <v-col class="no-space">
              <h6 style="font-size: 16px; font-weight: 300">
                Autres informations
              </h6>
            </v-col>
          </v-row>
          <v-row v-for="(name, key) in meta_data" :key="key" class="no-space">
            <v-col v-if="key && name" class="no-space" md="3">
              <h6 style="font-size: 12px; font-weight: 300">{{ key }}:</h6>
            </v-col>
            <v-col v-if="key && name" class="no-space" md="3">
              <h6 style="font-size: 12px; font-weight: 500; text-align: left">
                {{ name }}
              </h6>
            </v-col>
          </v-row>
        </v-container>

        <v-spacer style="margin-top: 20px; margin-bottom: 150px"></v-spacer>
        <v-container
          style="
            border-style: solid;
            border-color: #b04647;
            border-width: 1px;
            border-radius: 6px;
          "
        >
          <h4 style="font-size: 12px; font-weight: 300; text-align: center">
            Powered by
            <span style="font-style: italic">
              <span style="color: #b04647; font-weight: 700">Webcoom</span>
            </span>
          </h4>

          <h4 style="font-size: 12px; font-weight: 300; text-align: center">
            Pour toutes vos préoccupations, contactez-nous au
            <span style="color: #b04647; font-weight: 700">
              contact@webcoom.net
            </span>
            ou appelez
            <span style="font-style: italic; color: #b04647; font-weight: 700">
              le +229 90 80 80 13
            </span>
          </h4>
        </v-container>
      </v-container>
    </div>
    <div v-show="error_message">
      <v-card
        class="mx-auto"
        max-width="400"
        elevation="6"
        style="background-color: #102e49"
      >
        <v-card-text v-show="error_message">
          <div>
            <h6
              style="
                font-weight: 900;
                color: #b04647;
                margin-top: 50px;
                margin-bottom: 50px;
                text-align: center;
              "
              class="mx-auto"
            >
              {{ error_message }}
            </h6>
          </div>
          <div style="font-weight: 700; text-align: center">
            <v-icon color="white" size="100px">
              fa-regular fa-face-frown
            </v-icon>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import logo from "../../public/mpay-removebg-preview.png";
import html2pdf from "html2pdf.js";
import i18n from "../i18n";

export default {
  name: "Home",

  components: {},
  props: {
    token: String,
  },
  data() {
    return {
      top_logo: logo,
      meta_data: null,
      error_message: null,
      firstname: null,
      lastname: null,
      email: null,
      phonenumber: null,
      paiement_type: null,
      amout_wbc: null,
      amount_custumer: null,
      transaction_date: null,
      facture_code: null,
      total_amount: null,
    };
  },

  created() {},
  mounted() {
    this.getInfoByToken();
  },
  created() {},
  methods: {
    //getinfo by token

    async getInfoByToken() {
      if (!this.token) {
        this.error_message = i18n.t("title.error");
      } else {
        await this.$store
          .dispatch("getCustomerInfo", this.token)
          .then((response) => {
            if (response.success) {
              this.firstname = response.data.firstname;
              this.lastname = response.data.lastname;
              this.email = response.data.email;
              this.amout_wbc = response.data.commission;
              (this.transaction_date = response.data.approved_at),
                (this.phonenumber = response.data.phone_transaction);
              this.facture_code = response.data.reference;
              this.amount_custumer = response.data.amount_compte_business;
              this.total_amount = response.data.amount_transferred;
              this.meta_data = JSON.parse(
                JSON.parse(response.data.info_transaction)
              ).transaction.custom_metadata;
              console.log(this.meta_data);
              if (response.data.network == "Stripe") {
                this.paiement_type = "Carte";
              } else {
                this.paiement_type = "Mobile";
              }
              this.generatePdf();
            } else {
              this.error_message = i18n.t("title.error");
            }
          })
          .catch((error) => {
            console.log(error);
            this.error_message = i18n.t("title.error");
          });
      }
    },

    //jspdf does not include the bootstrap style layout
    generatePdf() {
      var element = this.$refs.factureHtml;
      var opt = {
        margin: 0.01,
        filename: this.facture_code + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
    },
  },
};
</script>
<style scoped>
#factureHtml {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.light-text {
  font-size: 16px;
  font-weight: 100;
}
.no-space {
  padding: 0px !important;
  margin: 0px !important;
}

.divider {
  border-color: white !important;
}
</style>
